import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Image from "../components/image"
import SEO from "../components/seo"
import ContentSlider from "../components/slider";
import Animation from "../components/animation";
import Intro from "../assets/intro.svg"

import { graphql } from "gatsby"
import website from "../../config/website"
import { LocaleContext } from "../components/layout"
import List from "../components/List/list";


const BlogPage = ({ data: { posts }, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <>
      <SEO
        title={'Blog'}
        keywords={[`Blog`,`Gesundheit`, `Aktuelles`, `Publikationen`]}
      />

      <section id="posts">
        
          {posts && posts.edges && (
            <List locale={locale} posts={posts.edges} />
          )}
      </section>

    </>
  )
}


export default BlogPage


BlogPage.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
         query BlogPageQuery($locale: String!) {
           posts: allPrismicPost(
             sort: { fields: [data___date], order: DESC }
             filter: { lang: { eq: $locale } }
           ) {
             edges {
               node {
                 uid
                 data {
                   title {
                     text
                   }
                   subtitle {
                     text
                   }
                   teaser {
                     html
                   }
                   image {
                     alt
                     url
                     localFile {
                       childImageSharp {
                         fluid(maxWidth: 1400, quality: 90) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                   image_preview {
                     alt
                     url
                     localFile {
                       childImageSharp {
                         fluid(maxWidth: 1200, quality: 90) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                   date(formatString: "DD.MM.YYYY")
                   categories {
                     category {
                       document {
                         data {
                           name
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       `

